import { Component, OnInit, OnChanges, ViewChild, Input } from '@angular/core';

import { Chart } from 'chart.js';

import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { ScaleScorePerPeriodDTO } from '@app/CoreModule/Models/Report';
import { AuthService, UsersService, ReportsService } from '@app/CoreModule/Services';


@Component({
    selector: 'app-periods-line-chart',
    templateUrl: './periods-line-chart.component.html',
    styleUrls: ['./periods-line-chart.component.css']
})
export class PeriodsLineChartComponent implements OnInit {
    @Input() studentId: number;
    @Input() studentName: string;
    @Input() UATeacherName: string;

    @ViewChild('lineChart') private chartRef;
    chart: any;

    scales = [
        { 'Id': 11, 'Name': 'Total CAWS Score' },
        { 'Id': 1, 'Name': 'Adaptability' },
        { 'Id': 2, 'Name': 'Connectedness' },
        { 'Id': 3, 'Name': 'Conscientiousness' },
        { 'Id': 4, 'Name': 'Emotional Self-Regulation' },
        { 'Id': 5, 'Name': 'Empathy' },
        { 'Id': 6, 'Name': 'Initiative' },
        { 'Id': 7, 'Name': 'Mindfulness' },
        { 'Id': 8, 'Name': 'Optimism' },
        { 'Id': 9, 'Name': 'Self-Efficacy' },
        { 'Id': 10, 'Name': 'Social Compentence' }
    ];
    selectedScale = 11;
    data: ScaleScorePerPeriodDTO[][];

    constructor(private authService: AuthService, private filesService: UsersService, private reportsService: ReportsService) {
    }

    ngOnInit() {
        this.initChart();
    }

    ngOnChanges(studentId: any) {
        this.getData()
    }


    initChart() {
        this.chart = new Chart(this.chartRef.nativeElement, {
            type: 'line',
            data: {
                labels: [],
                datasets: []
            },
            options: {
                responsive: false,
                //legend: false,
                legend: {
                    display: true
                },
                tooltips: {
                    display: true,
                    mode: 'index',
                    intersect: false
                },
                hover: { mode: null },
                elements: {
                    point: {
                        pointStyle: "circle"
                    }
                },
                scales: {
                    yAxes: [{
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'T-Score'
                        },
                        ticks: {
                            suggestedMin: 20,
                            suggestedMax: 90
                        }
                    }],
                    xAxes: [{
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'UA Periods'
                        }
                    }]

                },
                /* "animation": {
                     "onComplete": function () {
                         var chartInstance = this.chart, ctx = chartInstance.ctx;
 
                         ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                         ctx.textAlign = 'center';
                         ctx.textBaseline = 'bottom';
                         ctx.font = "bold 13px Arial";
                         ctx.fillStyle = "rgba(0, 0, 255, 1)";
 
                         this.data.datasets.forEach(function (dataset, i) {
                             var meta = chartInstance.controller.getDatasetMeta(i);
                             meta.data.forEach(function (bar, index) {
                                 var data = dataset.data[index];
                                 var textX = bar._model.x;
                                 var textY = bar._model.y - 8;
 
                                 ctx.fillText(data, textX, textY);
                             });
 
                         });
                     }
                 }*/
            }

        });

        Chart.pluginService.register({
            beforeDraw: function (chart, easing) {
                var helpers = Chart.helpers;
                var ctx = chart.chart.ctx;
                var chartArea = chart.chartArea;

                var width = chartArea.right - chartArea.left;
                var height = chartArea.bottom - chartArea.top;
                var rowHeight = height / ((90 - 20) / 5);

                var j = chart.config.options.scaleId;

                var rgbColors;
                var lengths;
                lengths = [6, 3, 1.1, 3.9];
                rgbColors = [[112, 173, 71], [169, 208, 142], [255, 255, 0], [255, 51, 0]];

                ctx.fillStyle = 'rgba(255,255,255)';
                ctx.fillRect(chartArea.left, chartArea.top, width, height);

                ctx.save();
                var lc = 0;
                var rgb;
                for (var i = 0; i < 4; i++) {
                    rgb = rgbColors[i];
                    ctx.fillStyle = `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.6)`;
                    ctx.fillRect(chartArea.left, chartArea.top + lc * rowHeight, width, lengths[i] * rowHeight);
                    lc += lengths[i];
                }

                ctx.restore();

            }
        });
    }

    getData() {
        if (this.studentId) {
            this.reportsService.getStudentScaleScorePerPeriod(this.studentId).subscribe(
                (result: ScaleScorePerPeriodDTO[][]) => {
                    this.data = result;
                    this.setNewData();
                },
                error => this.authService.alertError("An error occured while requesting data from server. Please try again.")
            )
        }
    }

    setNewData() {
        this.chart.data.datasets = [];
        console.log(this.data);
        this.data.forEach((scaleData: ScaleScorePerPeriodDTO[], index) => {
            if (scaleData.length > 0 && scaleData[0].ScaleId == this.selectedScale) {
                var periodsNames = scaleData.map(d => d.PeriodName);
                this.chart.data.labels = ["", ...periodsNames, ""];
                var periodsScores = scaleData.map(d => d.ScoreTeacher);

                this.chart.data.datasets.push({
                    label: this.UATeacherName,
                    data: [NaN, ...periodsScores, NaN],
                    borderColor: 'rgba(0, 0, 255, 1)',
                    backgroundColor: 'rgba(0, 0, 255, 1)',
                    fill: false,
                    borderWidth: 1.3,
                    pointRadius: 3,
                    pointHoverRadius: 3,
                    lineTension: 0
                })

                var periodsScores = scaleData.map(d => d.ScoreStudent);

                this.chart.data.datasets.push({
                    label: this.studentName,
                    data: [NaN, ...periodsScores, NaN],
                    borderColor: 'rgba(0, 204, 0, 1)',
                    backgroundColor: 'rgba(0, 204, 0, 1)',
                    fill: false,
                    borderWidth: 1.3,
                    pointRadius: 3,
                    pointHoverRadius: 3,
                    lineTension: 0
                })

                var periodsScores = scaleData.map(d => d.ScoreParent1);
                var ParentName1 = scaleData.map(d => d.ParentName1)
                if (ParentName1.length > 0 && !!ParentName1[0]) {
                    this.chart.data.datasets.push({
                        label: ParentName1[0],
                        data: [NaN, ...periodsScores, NaN],
                        borderColor: 'rgba(255, 0, 0, 1)',
                        backgroundColor: 'rgba(255, 0, 0, 1)',
                        fill: false,
                        borderWidth: 1.3,
                        pointRadius: 3,
                        pointHoverRadius: 3,
                        lineTension: 0
                    })
                }

                var periodsScores = scaleData.map(d => d.ScoreParent2);
                var ParentName2 = scaleData.map(d => d.ParentName2)
                if (ParentName2.length > 0 && !!ParentName2[0]) {
                    this.chart.data.datasets.push({
                        label: ParentName2[0],
                        data: [NaN, ...periodsScores, NaN],
                        borderColor: 'rgba(255, 0, 0, 1)',
                        backgroundColor: 'rgba(255, 0, 0, 1)',
                        fill: false,
                        borderWidth: 1.3,
                        pointRadius: 3,
                        pointHoverRadius: 3,
                        lineTension: 0
                    })
                }


            }
        });

        this.chart.update();
    }

    /*getScaleColor(index) {
        if (index == 0) return 'rgba(0, 0, 255, 1)';
        else if (index == 4) return 'rgba(0, 200, 255, 1)';
        else if (index == 2) return 'rgba(190, 0, 255, 1)';
        else if (index == 3) return 'rgba(255, 0, 50, 1)';
        else if (index == 1) return 'rgba(20, 180, 100, 1)';
        else return 'rgba(0, 0, 0, 1)';
    }*/

    extractPdf() {
        this.filesService.getFontNormal().then(
            data => {
                if (data) {
                    const downloadedFile = new Blob([data['body']], { type: data['body'].type });
                    var reader = new FileReader();
                    reader.onload = () => {

                        this.filesService.getFontBold().then(
                            data => {
                                if (data) {
                                    const downloadedFile = new Blob([data['body']], { type: data['body'].type });
                                    var reader2 = new FileReader();
                                    reader2.onload = () => {
                                        this.extractPdfFonts(reader.result, reader2.result); //function edw
                                    }
                                    reader2.readAsText(downloadedFile);
                                }
                            },
                            error => this.authService.alertError("An error occured while exporting .PDF file. Please try again.")
                        );
                    }
                    reader.readAsText(downloadedFile);
                }
            },
            error => {
                this.authService.alertError("An error occured while exporting .PDF file. Please try again.")
            }
        );

    }

    async extractPdfFonts(normalFont, boldFont) {
        var doc = new jspdf("p", "mm", "a4"); //width: 210

        doc.addFileToVFS('Arimo-Regular-Normal.ttf', normalFont);
        doc.addFont("Arimo-Regular-Normal.ttf", "Arimo-Regular", "normal");
        doc.addFileToVFS('Arimo-Regular-Bold.ttf', boldFont);
        doc.addFont("Arimo-Regular-Bold.ttf", "Arimo-Regular", "bold");

        doc.setFont("Arimo-Regular");

        var position = 28;

        doc.setFontStyle('bold');
        doc.setFontSize(24);
        doc.setTextColor(0, 0, 0);
        doc.text("REPORTS", 10, position);

        doc.setDrawColor(2, 15, 152);
        doc.setLineWidth(0.6);
        doc.line(5, position + 2, 205, position + 2);

        doc.setFontStyle('bold');
        doc.setFontSize(16);
        doc.setTextColor(0, 0, 0);
        doc.text("T-Score Data Graph Per Period", 10, position + 20);


        doc.setFontStyle('normal');
        doc.setFontSize(12);
        doc.text("Student", 10, position + 28);
        doc.setTextColor(2, 15, 152);
        doc.text(this.studentName, 35, position + 28);

        var data = document.getElementById('canvas');
        await html2canvas(data, { logging: false }).then(canvas => {
            var imgWidth = 170;
            var imgHeight = canvas.height * imgWidth / canvas.width;

            const contentDataURL = canvas.toDataURL('image/png')
            doc.addImage(contentDataURL, 'PNG', 20, position + 50, imgWidth, imgHeight);  //width: 1140,height: 491
        });

        doc.save("Student Graph.pdf");
    }

}

import { Component, OnInit, HostBinding } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { Globals } from '@app/globals';
import { DomSanitizer } from '@angular/platform-browser';

import * as $ from 'jquery';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'app';

    @HostBinding("attr.style")
    public get myStyles(): any {
        return this.sanitizer.bypassSecurityTrustStyle(
            `--highRisk: '${this.globals.highRisk}';` +
            `--concern: '${this.globals.concern}';` +
            `--typical: '${this.globals.typical}';` +
            `--strength: '${this.globals.strength}';` +
            `--backgroundColor: rgba(0, 123, 255, 0.33);` +
            `--borderColor: rgba(2, 15, 152, 0.83);`);
    }

    constructor(private router: Router, private sanitizer: DomSanitizer, private globals: Globals) {
        router.events.subscribe((val) => {
            // see also 
            // console.log(val)
        });
    }

    ngOnInit() {
        $(window).on('load', () => {
            if (/*@cc_on!@*/false || !!document['documentMode']) {
                (document.styleSheets[0] as CSSStyleSheet).addRule('.material-icons', 'opacity: 1 !important;', -1);
            }
            else {
                var style = $('<style>.material-icons { opacity: 1 !important; }</style>')
                $('html > head').append(style);
            }
        });
    }


}




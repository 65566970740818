import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { ChangePasswordComponent } from '@app/SharedModule/shared/Components/staff/change-password/change-password.component';
import { CustomRoles } from '@app/CoreModule/Models/CustomRoles';
import { AuthService, UaPeriodService, UsersService } from '@app/CoreModule/Services';
import { UAPeriodDTO } from '@app/CoreModule/Models/UAPeriod';


enum admin {
    schools = 1,
    staff = 2,
    students = 3,
    uaperiod = 4,
    reports = 5,
    resources = 7
}
enum rater {
    uaperiod = 8,
    otherReports = 6,
    resources = 9
}
enum accounts {
    clients = 10
}
enum user {
    myAccount = 11
}
declare const InstallTrigger: any;

@Component({
    selector: 'navigation-bar',
    templateUrl: './navigation-bar.component.html',
    styleUrls: ['./navigation-bar.component.css']
})
export class NavigationBarComponent implements OnInit {
    colors: any = {};
    navClicked = 0;
    isRater: boolean;
    isSuperadmin: boolean;
    allow12: boolean;
    period: UAPeriodDTO;
    fullName = "";
    closePeriod = true;

    constructor(private router: Router, private authService: AuthService, private usersService: UsersService, private uaperiodservice: UaPeriodService, public dialog: MatDialog) {
        var role = this.authService.getRole();
        this.allow12 = (role === CustomRoles.DistrictAdministrator || role === CustomRoles.DistrictStaff);
    }


    ngOnInit() {
        this.isSuperadmin = this.authService.isAdmin();
        this.navHighlight();
        this.isRater = this.authService.getRole() === CustomRoles.RaterOnly;

        this.getAccountEmail();

        var currFFZoom = 1;

        $('#plusBtn').on('click', function () {
            var isFirefox = typeof InstallTrigger !== 'undefined';

            if (!isFirefox) {
                currFFZoom += 0.05;
                document.getElementById("page-content").style['zoom'] = currFFZoom.toString();
            }
            else {
                currFFZoom += 0.03;
                (<HTMLElement>document.getElementsByClassName("body")[0]).style.webkitTransform = `scale(${currFFZoom})`;
                (<HTMLElement>document.getElementsByClassName("body")[0]).style.webkitTransformOrigin = `top left`;
            }
        });

        $('#minusBtn').on('click', function () {
            var isFirefox = typeof InstallTrigger !== 'undefined';

            if (!isFirefox) {
                currFFZoom -= 0.05;
                document.getElementById("page-content").style['zoom'] = currFFZoom.toString();
            }
            else {
                currFFZoom -= 0.03;
                (<HTMLElement>document.getElementsByClassName("body")[0]).style.webkitTransform = `scale(${currFFZoom})`;
                (<HTMLElement>document.getElementsByClassName("body")[0]).style.webkitTransformOrigin = `top left`;
            }
        });

        this.uaperiodservice.getLastUAPeriod().subscribe(
            result => {
                this.period = result;
            },
            errors => {
                if ((errors.status == 400 || errors.status == 500) && typeof errors.error == "string") {
                    this.authService.alertError(errors.error)
                }
                else {
                    this.authService.alertError('An error occured while requesting UA period from server. Please try again.')
                }
            }
        );

    }

    getAccountEmail() {
        this.usersService.getAccountName().subscribe(
            (result: string) => { this.fullName = result },
            error => {
                this.fullName = "";
                setTimeout(() => {
                    this.getAccountEmail()
                }, 5000);
            }
        )
    }


    get admin() { return admin; }
    get rater() { return rater; }
    get accounts() { return accounts; }
    get user() { return user; }

    navHighlight() {
        var loc = window.location.href.split('/');
        if (loc.indexOf('admin') != -1) this.navClicked = admin[loc[loc.indexOf('admin') + 1]];
        else if (loc.indexOf('rater') != -1) this.navClicked = rater[loc[loc.indexOf('rater') + 1]];
        else if (loc.indexOf('accounts') != -1) this.navClicked = accounts[loc[loc.indexOf('accounts') + 1]];
    }

    logout() {
        this.authService.logout();
    }

    openDialog() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        const dialogRef = this.dialog.open(ChangePasswordComponent, {
            width: '600px'
        });
        dialogRef.afterClosed().subscribe(data => {
            if (data) {
                this.usersService.changePassword(data).subscribe(
                    result => {
                        alert("Your password has been successfully changed.")
                    },
                    error => this.authService.alertError("Your password has not been changed. Please try again.")
                )
            }
        });

    }
}



import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { StudentFormDataRaterDTO, StudentFormDataParentDTO, ResultRaterDTO, StudentUAFullResultsDTO, StudentUAQuestionAnswersDTO, ParentLetterDTO } from '../Models/Assessment';
import { GetParentLinkDTO, GetStudentLinkDTO } from '../Models/UAPeriod';



@Injectable()
export class AssessmentService {
    notAssessedStudents = [];

    constructor(private http: HttpClient, public router: Router, @Inject('BASE_URL') public baseUrl: string) { }

    getRaterAssessmentData(studentId) {
        return this.http.get<StudentFormDataRaterDTO>(this.baseUrl + `api/Assessment/GetRaterAssessmentData/${studentId}`).map(result => {
            return result;
        }).shareReplay();
    }

    getParentAssessmentData(studentId, parentId, token) {
        return this.http.get<StudentFormDataParentDTO>(this.baseUrl + `api/Assessment/GetParentAssessmentData/${studentId}/${parentId}/${token}`).map(result => {
            return result;
        }).shareReplay();
    }
    getStudentSelfAssessmentData(studentId, token) {
        return this.http.get<StudentFormDataParentDTO>(this.baseUrl + `api/Assessment/GetStudentSelfAssessmentData/${studentId}/${token}`).map(result => {
            return result;
        }).shareReplay();
    }
    getStudentSelfAssessmentDataForNoNet(studentId) {
        return this.http.get<StudentFormDataParentDTO>(this.baseUrl + `api/Assessment/GetStudentSelfAssessmentDataForNoNet/${studentId}`).map(result => {
            return result;
        }).shareReplay();
    }

    submitRaterAssessment(assessment) {
        return this.http.post(this.baseUrl + 'api/Assessment/SubmitRaterAssessment', assessment)
            .shareReplay();
    }

    submitParentAssessment(assessment) {
        return this.http.post(this.baseUrl + 'api/Assessment/SubmitParentAssessment', assessment)
            .shareReplay();
    }

    submitStudentAssessment(assessment) {
        return this.http.post(this.baseUrl + 'api/Assessment/SubmitStudentAssessment', assessment)
            .shareReplay();
    }

    submitStudentAssessmentForNoNet(assessment) {
        return this.http.post(this.baseUrl + 'api/Assessment/SubmitStudentAssessmentForNoNet', assessment)
            .shareReplay();
    }


    submitAndNextRaterAssessment(assessment) {
        return this.http.post<StudentFormDataRaterDTO>(this.baseUrl + 'api/Assessment/SubmitAndNextRaterAssessment', assessment)
            .shareReplay();
    }

    saveRaterAssessment(assessment) {
        return this.http.post(this.baseUrl + 'api/Assessment/SaveRaterAssessment', assessment)
            .shareReplay();
    }

    getAssessmentStatus(studentId) {
        return this.http.get<boolean>(this.baseUrl + `api/Assessment/GetAssessmentStatus/${studentId}`).map(result => {
            return result;
        }).shareReplay();
    }

    getAssessmentResults(periodId) {
        return this.http.get<ResultRaterDTO[]>(this.baseUrl + `api/Assessment/GetAssessmentResults/${periodId}`).map(result => {
            return result;
        }).shareReplay();
    }

    sendCheckRosterEmails(periodId, districtWide, schoolIds, gradeIds) {
        return this.http.post(this.baseUrl + 'api/UAPeriod/CheckRoster', { periodId, districtWide, schoolIds, gradeIds })
            .shareReplay();
    }

    sendActivePeriodRaterEmails(periodId, districtWide, schoolIds, gradeIds) {
        return this.http.post(this.baseUrl + 'api/UAPeriod/ActivePeriodRater', { periodId, districtWide, schoolIds, gradeIds })
            .shareReplay();
    }
    GetStudentLinks(periodId, districtWide, schoolIds, gradeIds) {
        var schools = schoolIds.map(id => `schoolIds=${id}`);
        var grades = gradeIds.map(id => `gradeIds=${id}`);
        var combined = [...schools, ...grades].join("&");
        return this.http.get<GetStudentLinkDTO[]>(this.baseUrl + `api/UAPeriod/GetStudentLinks/${periodId}/${districtWide}?${combined}`).map(result => {
            return result;
        }).shareReplay();
    }
    GetParentLinks(periodId, districtWide, schoolIds, gradeIds) {
        var schools = schoolIds.map(id => `schoolIds=${id}`);
        var grades = gradeIds.map(id => `gradeIds=${id}`);
        var combined = [...schools, ...grades].join("&");
        return this.http.get<GetParentLinkDTO[]>(this.baseUrl + `api/UAPeriod/GetParentLinks/${periodId}/${districtWide}?${combined}`).map(result => {
            return result;
        }).shareReplay();
    }

    sendActivePeriodGuardianEmails(periodId, districtWide, schoolIds, gradeIds) {
        return this.http.post(this.baseUrl + 'api/UAPeriod/ActivePeriodGuardian', { periodId, districtWide, schoolIds, gradeIds })
            .shareReplay();
    }
    sendActivePeriodStudentEmails(periodId, districtWide, schoolIds, gradeIds) {
        return this.http.post(this.baseUrl + 'api/UAPeriod/ActivePeriodStudent', { periodId, districtWide, schoolIds, gradeIds })
            .shareReplay();
    }

    getStudentUAResults(studentId) {
        return this.http.get<StudentUAFullResultsDTO[]>(this.baseUrl + `api/Assessment/GetStudentUAResults/${studentId}`).map(result => {
            return result;
        }).shareReplay();
    }

    getStudentUAAnswers(studentId, periodId) {
        return this.http.get<StudentUAQuestionAnswersDTO>(this.baseUrl + `api/Assessment/GetStudentUAAnswers/${studentId}/${periodId}`).map(result => {
            return result;
        }).shareReplay();
    }

    getParentLetterData(studentsIds, periodId) {
        periodId = parseInt(periodId);
        return this.http.post<ParentLetterDTO[]>(this.baseUrl + 'api/Assessment/GetParentLetterData', { studentsIds, periodId })
            .shareReplay();
    }

    getCSVResourcesData(data) {
        return this.http.post<string[][]>(this.baseUrl + 'api/Assessment/GetCSVResourcesData', data)
            .shareReplay();
    }

    saveCategoryComment(studentId: number, periodId: number, categoryId: number, comment: string) {
        return this.http.post(this.baseUrl + 'api/Assessment/SaveCategoryComment', { StudentId: studentId, PeriodId: periodId, CategoryId: categoryId, Comment: comment })
            .shareReplay();
    }

    saveGeneralComment(studentId: number, periodId: number, comment: string) {
        return this.http.post(this.baseUrl + 'api/Assessment/SaveGeneralComment', { StudentId: studentId, PeriodId: periodId, CategoryId: 0, Comment: comment })
            .shareReplay();
    }



}

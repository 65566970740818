import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

    transform(items: any[], route: any, filter: any): any[] {
        //console.log(items, route, filter)
        return items.sort((a, b) => {
            let aLC: string = '';
            let bLC: string = '';
            filter = filter.toString();
            if (filter.indexOf('.') > -1) {
                var attrs = filter.split('.');
                if (attrs[0] != undefined && attrs[0] != "") { //px  obj { attr1 : 'rand', attr2: {attr21: 'rand21', attr22: 'rand22'} } -> (attr2.attr21.attr22) = rand21 rand22
                    for (var i = 1; i < attrs.length; i++) {
                        if (a[attrs[0]][attrs[i]] == null) return +1;
                        else if (b[attrs[0]][attrs[i]] == null) return -1;
                        aLC += a[attrs[0]][attrs[i]].toString().toLowerCase() + ' ';
                        bLC += b[attrs[0]][attrs[i]].toString().toLowerCase() + ' ';
                    }
                }
                else { //px  obj { attr1 : 'rand1', attr2: 'rand2'} -> (.attr1.attr2) = rand1 rand2
                    for (var i = 1; i < attrs.length; i++) {
                        if (a[attrs[i]] == null) return +1;
                        else if (b[attrs[i]] == null) return -1;
                        aLC += a[attrs[i]].toString().toLowerCase() + ' ';
                        bLC += b[attrs[i]].toString().toLowerCase() + ' ';
                    }
                }
            }
            else if (a[filter] != null && b[filter] != null) {  // px  obj { attr1 : 'rand1', attr2: 'rand2'} -> (attr1) = rand1
                aLC = a[filter].toString().toLowerCase();
                bLC = b[filter].toString().toLowerCase();
            }
            else {
                //console.log("PREPEI NA EINAI NULL GIA NA MPEI EDO", a[filter], b[filter])
                if (!a[filter]) return +1;
                else return -1;
            }
            if (route == "more") return aLC < bLC ? -1 : (aLC > bLC ? 1 : 0);
            else if (route == "less") return aLC > bLC ? -1 : (aLC < bLC ? 1 : 0);
        });
    }

}
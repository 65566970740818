import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogRef } from '@angular/material';
import { StudentFullNameDTO } from '@app/CoreModule/Models/Student';
import { StaffService, AuthService } from '@app/CoreModule/Services';
import 'rxjs/Rx';

@Component({
    selector: 'app-deactivate-member-staff',
    templateUrl: './deactivate-member-staff.component.html',
    styleUrls: ['./deactivate-member-staff.component.css']
})
export class DeactivateMemberStaffComponent implements OnInit {

    staffId: number = 0;
    students: StudentFullNameDTO[] = [];

    constructor(private staffService: StaffService, private authService: AuthService, public thisDialogRef: MatDialogRef<DeactivateMemberStaffComponent>, @Inject(MAT_DIALOG_DATA) public data: number) { }

    ngOnInit() {
        this.staffId = this.data;

        this.staffService.getRaterOwnStudents(this.staffId).subscribe(
            (students: StudentFullNameDTO[]) => {
                this.students = students;
            },
            error => this.authService.alertError("An error occured while requesting educator's student data from server. Please try again.")
        )
    }

    onCloseConfirm() {
        this.staffService.deactivateStaff(this.staffId).subscribe(
            result => this.thisDialogRef.close(true),
            error => {
                if ((error.status == 400 || error.status == 500) && typeof error.error == "string") {
                    this.authService.alertError(error.error)
                }
                else {
                    this.authService.alertError("An error occured while deactivating staff member.Please try again.")
                }
            }
        )
    }

    onCloseCancel() {
        this.thisDialogRef.close(false);
    }
}

import { Component, OnInit, trigger, transition, style, animate } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Inject } from "@angular/core";
import { DOCUMENT } from '@angular/platform-browser';

import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { EnsureActionModalComponent } from '@app/SharedModule/shared/Components/ensure-action-modal/ensure-action-modal.component';
import { QuestionTextDTO, StudentFormDataRaterDTO, StudentFormDataParentDTO } from '@app/CoreModule/Models/Assessment';
import { StudentIdFullNameDTO } from '@app/CoreModule/Models/Student';
import { UaPeriodIdNameDTO } from '@app/CoreModule/Models/UAPeriod';
import { AuthService, AssessmentService, UaPeriodService } from '@app/CoreModule/Services';


@Component({
    selector: 'app-complete-form-student',
    templateUrl: './complete-form-student.component.html',
    styleUrls: ['./complete-form-student.component.css']
})
export class CompleteFormStudentComponent implements OnInit {

    SubmitTypes = {
        Submit: 1,
        SubmitAndNext: 2
    }

    period: UaPeriodIdNameDTO = {} as UaPeriodIdNameDTO;
    student: StudentIdFullNameDTO = {} as StudentIdFullNameDTO;
    form = { 'Comment': '' };
    showNextButton = false;
    sumbitType = 1;
    lockSubmit = false;
    isAdminmode = false;
    radioOptions = [4, 3, 2, 1];

    questions: QuestionTextDTO[] = [];

    constructor(private authService: AuthService, public dialog: MatDialog, private location: Location, private router: Router, @Inject(DOCUMENT) private document: Document, private assessmentService: AssessmentService, private uaPeriodService: UaPeriodService, private route: ActivatedRoute) {
        var studentId = +this.route.snapshot.paramMap.get('id');
        console.log(this.router.url.indexOf('admin'))
        if (this.router.url.includes('admin')) {
            this.isAdminmode = true;
            this.assessmentService.getStudentSelfAssessmentDataForNoNet(studentId).subscribe(
                (result: StudentFormDataParentDTO) => {
                    this.period = result['Period'];
                    this.student = result['Student'];
                    this.questions = result['Questions'];
                    this.lockSubmit = false;
                },
                error => {
                    this.lockSubmit = true;
                    if ((error.status == 400 || error.status == 500) && typeof error.error == "string") {
                        this.authService.alertError(error.error);
                    }
                    else {
                        this.authService.alertError('An error occured while requesting this student\'s data from server. Please try again.');
                    }
                }
            );
        } else {
            this.assessmentService.getRaterAssessmentData(studentId).subscribe(
                (result: StudentFormDataRaterDTO) => {
                    this.setDataToObjects(result);
                    this.lockSubmit = false;
                },
                error => {
                    this.lockSubmit = true;
                    if ((error.status == 400 || error.status == 500) && typeof error.error == "string") {
                        this.authService.alertError(error.error);
                    }
                    else {
                        this.authService.alertError('An error occured while requesting this student\'s data from server. Please try again.');
                    }
                }
            );
        }
        if (location.path().indexOf("rater") != -1) this.showNextButton = true;
    }

    ngOnInit() {
    }

    setDataToObjects(result: StudentFormDataRaterDTO) {
        this.period = result['Period'];
        this.student = result['Student'];
        this.questions = result['Questions'];
        result['Answers'].forEach(answer => {
            this.form[answer['QuestionId']] = answer['Answer'].toString()
        });
        this.form['Comment'] = result['Comment'];
    }

    submitHandler(formElement: NgForm) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        const dialogRef = this.dialog.open(EnsureActionModalComponent, {
            width: '600px',
            data: `Are you sure that you want to submit the questionnaire for '${this.student.FullName}'?`
        });
        dialogRef.afterClosed().subscribe(confirm => {
            if (confirm) {
                if (this.sumbitType == this.SubmitTypes.Submit) {
                    this.submit();
                }
                else if (this.sumbitType == this.SubmitTypes.SubmitAndNext) {
                    this.submitAndNext(formElement);
                }
            }
        });
    }

    submit() {
        if (this.router.url.includes('admin')) {
            var data = this.prepareData();
            this.lockSubmit = true;
            this.assessmentService.submitStudentAssessmentForNoNet(data).subscribe(
                result => {
                    this.authService.alertSuccess('Univesal Assessment has been succesfully submitted.');
                    this.lockSubmit = false;
                    this.goBack();
                },
                error => {
                    this.lockSubmit = false;
                    if ((error.status == 400 || error.status == 500) && typeof error.error == "string") {
                        this.authService.alertError(error.error);
                    }
                    else {
                        this.authService.alertError('Universal Assessment has failed to be submitted. Please try again.');
                    }
                }
            )
        }
        else {
            var data = this.prepareData();
            this.lockSubmit = true;
            this.assessmentService.submitRaterAssessment(data).subscribe(
                result => {
                    this.authService.alertSuccess('Univesal Assessment has been succesfully submitted.');
                    this.lockSubmit = false;
                    this.goBack();
                },
                error => {
                    this.lockSubmit = false;
                    if ((error.status == 400 || error.status == 500) && typeof error.error == "string") {
                        this.authService.alertError(error.error);
                    }
                    else {
                        this.authService.alertError('Universal Assessment has failed to be submitted. Please try again.');
                    }
                }
            )
        }
    }

    submitAndNext(formElement: NgForm) {
        var data = this.prepareData();
        this.lockSubmit = true;
        this.assessmentService.submitAndNextRaterAssessment(data).subscribe(
            (result: StudentFormDataRaterDTO) => {
                this.authService.alertSuccess('Univesal Assessment has been succesfully submitted.');
                if (result) {
                    this.form = { 'Comment': '' };
                    this.router.navigate(['/rater/uaperiod/students/' + result['Student']['Id'].toString() + '/form']);
                    this.setDataToObjects(result);
                    window.scrollTo(0, 0);
                    document.getElementById('body').scrollTop = 0;
                    document.getElementById('questionsBody').scrollTop = 0;
                    formElement.reset();
                    formElement.resetForm();
                }
                else this.router.navigate(['/rater/uaperiod']);
                this.lockSubmit = false;
            },
            error => {
                this.lockSubmit = false;
                if ((error.status == 400 || error.status == 500) && typeof error.error == "string") {
                    this.authService.alertError(error.error);
                }
                else {
                    this.authService.alertError('Universal Assessment has failed to be submitted. Please try again.');
                }
            },
        )
    }

    save() {
        var data = this.prepareData();
        this.lockSubmit = true;
        this.assessmentService.saveRaterAssessment(data).subscribe(
            result => { this.authService.alertSuccess('Universal Assessment has been successfully saved.'); this.lockSubmit = false; },
            error => { this.authService.alertError('Universal Assessment has failed to be saved.'); this.lockSubmit = false; },
        )
    }

    prepareData() {
        var answers = [];
        Object.keys(this.form).forEach(key => {
            if (key != "Comment")
                answers.push({ QuestionId: key, Answer: +this.form[key] })
        })

        return {
            StudentId: this.student.Id,
            PeriodId: this.period.Id,
            Answers: answers,
            Comment: this.form['Comment']
        }
    }

    goBack() {
        this.location.back();
    }
}

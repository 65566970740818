import 'rxjs/add/observable/of';
import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from './CoreModule/Services';
import { CustomRoles } from './CoreModule/Models/CustomRoles';


@Injectable()
export class SelectivePreloadingStrategy implements PreloadingStrategy {
    //preloadedModules: any[] = [];
    constructor(private router: Router, private authService: AuthService) { }

    preload(route: Route, load: () => Observable<any>): Observable<any> {

        var path = this.router;

        if (path.url.split('/')[1] == "parent" || path.url.split('/')[1] == "student-self-assessment") {
            return Observable.of(null);
        }

        if (route.data && route.data['preload'] && route.data['myvar'] && this.authService.isLoggedIn()) {
            if (path.url.split('/')[1] == "accounts") {
                if (route.data['myvar'] == "appRater" || route.data['myvar'] == "appAdmin") {
                    //console.log("blocked, only superadmin - " + route.data['myvar']);
                    return Observable.of(null);
                }
            }
            if (path.url.split('/')[1] == "admin") {
                if (route.data['myvar'] == "appSuperAdmin") {
                    //console.log("blocked, for admins - " + route.data['myvar']);
                    return Observable.of(null);
                }
            }
            else if (path.url.split('/')[1] == "rater") {
                //console.log(route.data['myvar'] == "appSuperAdmin", route.data['myvar'] == "appAdmin" && this.authService.getRole() === CustomRoles.RaterOnly)
                if (route.data['myvar'] == "appSuperAdmin" || (route.data['myvar'] == "appAdmin" && this.authService.getRole() === CustomRoles.RaterOnly)) {
                    //console.log("blocked, only raters - " + route.data['myvar']);
                    return Observable.of(null);
                }
            }
            else {
                //console.log("blocked all" + route.data['myvar']);
                return Observable.of(null);
            };

            //console.log("passed " + route.data['myvar']);
            return load();
        }
        else {
            return Observable.of(null);
        }
    }
}
import { Component, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { UaStatusReport, UaDataOverviewPyramidsDTO, UaDataOverviewStudentScoresDTO, UaDataOverviewSchoolsAverageDTO, ProgressMonitoringClassScoresDTO, ProgressMonitoringPeriodAverageDTO, ScaleScorePerPeriodDTO, ProgressMonitoringGroupScaleScoresDTO, UaDataOverviewGendersAverageDTO, UaDataOverviewEthnicitiesAverageDTO, UaDataOverviewRacesAverageDTO } from '../Models/Report';
import { UAPeriodDTO } from '../Models/UAPeriod';
import { SchoolNameDTO } from '../Models/School';
import { Grade } from '../Models/Grade';

export class OtherReportsData {
    FormType: number;
    UAPeriod: number;
    Schools: number[];
    Grades: number[];
    Teachers: number[];
    Scale: number;
    AllUAPeriods: UAPeriodDTO[];
    AllSchools: SchoolNameDTO[];
    AllGrades: Grade[];
    AllStaff: any[];

    constructor() {
        this.FormType = 1;
        this.UAPeriod = 0;
        this.Schools = [];
        this.Grades = [];
        this.Teachers = [];
        this.Scale = 11;
        this.AllUAPeriods = [];
        this.AllSchools = [];
        this.AllGrades = [];
        this.AllStaff = [];
    }
}

@Injectable()
export class ReportsService {

    constructor(private http: HttpClient, @Inject('BASE_URL') public baseUrl: string) { }

    reportsFormType = new BehaviorSubject(1);
    currentFormType = this.reportsFormType.asObservable();
    changeReportsFormType(message: number) {
        this.reportsFormType.next(message)
    }

    otherReportsData = new BehaviorSubject(new OtherReportsData());
    currentOtherReportsData = this.otherReportsData.asObservable();
    changeOtherReportsData(message: OtherReportsData) {
        this.otherReportsData.next(message)
    }

    getAllSchoolsUaStatusReport(type) {
        return this.http.get<UaStatusReport[]>(this.baseUrl + `api/Reports/GetAllSchoolsUaStatusReport/${type}`).map(result => {
            return result;
        }).shareReplay();
    }

    getSchoolUaStatusReport(type, schoolId) {
        return this.http.get<UaStatusReport[]>(this.baseUrl + `api/Reports/GetSchoolUaStatusReport/${type}/${schoolId}`).map(result => {
            return result;
        }).shareReplay();
    }

    getAssessmentResults(type, periodId, schoolsIds, teachersIds, gradesIds) {
        return this.http.post<UaDataOverviewPyramidsDTO[]>(this.baseUrl + 'api/Reports/GetAssessmentResults', { UserType: type, PeriodId: periodId, SchoolsIds: schoolsIds, TeachersIds: teachersIds, GradesIds: gradesIds })
            .shareReplay();
    }

    getStudentsWithAssessmentResults(type, periodId, schoolsIds, gradesIds, teachersIds) {
        return this.http.post<UaDataOverviewStudentScoresDTO[]>(this.baseUrl + 'api/Reports/GetStudentsWithAssessmentResults', { UserType: type, PeriodId: periodId, SchoolsIds: schoolsIds, GradesIds: gradesIds, TeachersIds: teachersIds })
            .shareReplay();
    }

    getClassStudentScoresPerScale(type, schoolsIds, teachersIds, gradesIds, categoryId) {
        return this.http.post<ProgressMonitoringClassScoresDTO[]>(this.baseUrl + 'api/Reports/GetClassStudentScoresPerScale', { UserType: type, SchoolsIds: schoolsIds, TeachersIds: teachersIds, GradesIds: gradesIds, CategoryId: categoryId })
            .shareReplay();
    }

    getSchoolAverageScorePerPeriod(type, periodsIds, schoolsIds, categoryId) {
        return this.http.post<ProgressMonitoringPeriodAverageDTO[]>(this.baseUrl + 'api/Reports/GetSchoolAverageScorePerPeriod', { UserType: type, PeriodsIds: periodsIds, SchoolsIds: schoolsIds, CategoryId: categoryId })
            .shareReplay();
    }

    getStudentScaleScorePerPeriod(studentId) {
        return this.http.get<ScaleScorePerPeriodDTO[][]>(this.baseUrl + `api/Reports/GetStudentScaleScorePerPeriod/${studentId}`).map(result => {
            return result;
        }).shareReplay();
    }

    getPeriodGroupScaleScores(type, schoolsIds, teachersIds, gradesIds) {
        return this.http.post<ProgressMonitoringGroupScaleScoresDTO>(this.baseUrl + 'api/Reports/GetPeriodGroupScaleScores', { UserType: type, SchoolsIds: schoolsIds, TeachersIds: teachersIds, GradesIds: gradesIds })
            .shareReplay();
    }


    /* Other Reports */
    getSchoolsAverageScores(type, periodId, categoryId, schoolsIds, gradesIds, teachersIds) {
        return this.http.post<UaDataOverviewSchoolsAverageDTO[]>(this.baseUrl + 'api/Reports/GetSchoolsAverageScores', { UserType: type, PeriodId: periodId, CategoryId: categoryId, SchoolsIds: schoolsIds, GradesIds: gradesIds, TeachersIds: teachersIds })
            .shareReplay();
    }

    getGendersAverageScores(type, periodId, categoryId, schoolsIds, gradesIds, teachersIds, races, ethnicities) {
        return this.http.post<UaDataOverviewGendersAverageDTO[]>(this.baseUrl + 'api/Reports/GetGendersAverageScores', { UserType: type, PeriodId: periodId, CategoryId: categoryId, SchoolsIds: schoolsIds, GradesIds: gradesIds, TeachersIds: teachersIds, Races: races, Ethnicities: ethnicities })
            .shareReplay();
    }

    getEthnicitiesAverageScores(type, periodId, categoryId, schoolsIds, gradesIds, teachersIds, genders, races) {
        return this.http.post<UaDataOverviewEthnicitiesAverageDTO[]>(this.baseUrl + 'api/Reports/GetEthnicitiesAverageScores', { UserType: type, PeriodId: periodId, CategoryId: categoryId, SchoolsIds: schoolsIds, GradesIds: gradesIds, TeachersIds: teachersIds, Genders: genders, Races: races })
            .shareReplay();
    }

    getRacesAverageScores(type, periodId, categoryId, schoolsIds, gradesIds, teachersIds, genders, ethnicities) {
        return this.http.post<UaDataOverviewRacesAverageDTO[]>(this.baseUrl + 'api/Reports/GetRacesAverageScores', { UserType: type, PeriodId: periodId, CategoryId: categoryId, SchoolsIds: schoolsIds, GradesIds: gradesIds, TeachersIds: teachersIds, Genders: genders, Ethnicities: ethnicities })
            .shareReplay();
    }


}
import { Component, OnInit } from '@angular/core';

import { MatDialog, MatDialogConfig } from "@angular/material";
import { LoginLinkStaffComponent } from '../login-link-staff/login-link-staff.component';
import { DeactivateMemberStaffComponent } from '../deactivate-member-staff/deactivate-member-staff.component';
import { ActivateMemberStaffComponent } from '../activate-member-staff/activate-member-staff.component';
import { ChangePasswordComponent } from '../change-password/change-password.component';

import { ActivatedRoute, Router } from '@angular/router';
import { trigger, transition, style, animate, state } from '@angular/animations'
import { CustomRoles } from '@app/CoreModule/Models/CustomRoles';
import { StaffDetailsDTO } from '@app/CoreModule/Models/Staff';
import { AuthService, StaffService, UsersService } from '@app/CoreModule/Services';


@Component({
    selector: 'app-details-staff',
    templateUrl: './details-staff.component.html',
    styleUrls: ['./details-staff.component.css'],
    animations: [
        trigger(
            'myAnimation',
            [
                transition(
                    ':enter', [
                    style({ opacity: 0 }),
                    animate('500ms', style({ 'opacity': 1 }))
                ]
                )]
        )
    ]
})
export class DetailsStaffComponent implements OnInit {

    staff: StaffDetailsDTO = {} as StaffDetailsDTO;
    myAcc = false;
    successfulDeactivation = false;
    successfulActivation = false;

    allow123 = false;
    isDistrictStaffBlockAdminEdit = false;
    allowEditMyAccount = false;

    showStudents = false;

    alertPopup = { 'text': '', 'visible': false, 'class': 'success' };

    constructor(private authService: AuthService, private staffService: StaffService, private usersService: UsersService, public dialog: MatDialog, private route: ActivatedRoute, private router: Router) {
        var role = this.authService.getRole();
        this.allow123 = (role === CustomRoles.DistrictAdministrator || role === CustomRoles.DistrictStaff || role === CustomRoles.SchoolAdministrator)
        this.isDistrictStaffBlockAdminEdit = (role === CustomRoles.DistrictStaff); //wait to get staff info

        var id = +this.route.snapshot.paramMap.get('id');
        if (id) {
            this.staffService.getStaffDetailsById(id).subscribe(
                (staff: StaffDetailsDTO) => {
                    this.staff = staff;
                    this.isDistrictStaffBlockAdminEdit = (this.isDistrictStaffBlockAdminEdit && staff.RoleName == CustomRoles.DistrictAdministrator);
                },
                error => this.router.navigate(['/admin/staff']),
            )
        }
        else {
            this.myAcc = true;
            this.staffService.getStaffOwnDetails().subscribe(
                (staff: StaffDetailsDTO) => {
                    this.staff = staff;
                    this.allowEditMyAccount = (staff.RoleName == CustomRoles.DistrictAdministrator || staff.RoleName == CustomRoles.DistrictStaff || staff.RoleName == CustomRoles.SchoolAdministrator);
                }
            )
        }
    }

    sendSetupAccountEmail() {
        this.alertPopup = { 'text': '', 'visible': false, 'class': 'success' };
        this.staffService.sendSetupAccountEmail(this.staff['Id']).subscribe(
            result => {
                this.alertPopup = { 'text': 'Email successfully sent.', 'visible': true, 'class': 'success' };
            },
            error => {
                this.alertPopup = { 'text': 'Email failed to be sent. Please try again.', 'visible': true, 'class': 'danger' };
            }
        );
    }

    ngOnInit() { }

    showSchools() {
        return this.staff.Schools.map(s => s.Name).join(', ');
    }

    openDialog(dial) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        if (dial == "sendlink") {
            const dialogRef = this.dialog.open(LoginLinkStaffComponent, {
                width: 'auto'
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.sendSetupAccountEmail();
                }
            });
        }
        else if (dial == "deactivate") {
            const dialogRef = this.dialog.open(DeactivateMemberStaffComponent, {
                width: '600px',
                data: this.staff['Id']
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.staff['Active'] = false;
                    this.successfulDeactivation = true;
                    this.successfulActivation = false;
                }
            });
        }
        else if (dial == "activate") {
            const dialogRef = this.dialog.open(ActivateMemberStaffComponent, {
                width: '600px',
                data: this.staff['Id']
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.staff['Active'] = true;
                    this.successfulDeactivation = false;
                    this.successfulActivation = true;
                }
            });
        }
        else if (dial == "changepassword") {
            const dialogRef = this.dialog.open(ChangePasswordComponent, {
                width: '600px'
            });
            dialogRef.afterClosed().subscribe(data => {
                if (data) {
                    this.alertPopup = { 'text': '', 'visible': false, 'class': 'success' };
                    this.usersService.changePassword(data).subscribe(
                        result => {
                            this.alertPopup = { 'text': 'Password has been successfully changed.', 'visible': true, 'class': 'success' };
                        },
                        error => {
                            this.alertPopup = { 'text': 'Password has not been changed.Please try again', 'visible': true, 'class': 'danger' };
                        }
                    )
                }
            });
        }
    }
}

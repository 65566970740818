import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UAPeriodDTO, EmailRequestDTO } from '../Models/UAPeriod';

@Injectable()
export class UaPeriodService {

    constructor(private http: HttpClient, @Inject('BASE_URL') public baseUrl: string) { }

    getUAPeriods() {
        return this.http.get<Array<UAPeriodDTO>>(this.baseUrl + 'api/UAPeriod/GetAllUAPeriods')
            .shareReplay();
    }

    getUAPeriodById(id: number) {
        return this.http.get<UAPeriodDTO>(this.baseUrl + `api/UAPeriod/GetUAPeriodById/${id}`)
            .shareReplay();
    }

    getPeriodEmailRequests(id: number) {
        return this.http.get<Array<EmailRequestDTO>>(this.baseUrl + `api/UAPeriod/GetPeriodEmailRequests/${id}`)
            .shareReplay();
    }

    addUAPeriod(period) {
        return this.http.post(this.baseUrl + 'api/UAPeriod/Add', period)
            .shareReplay();
    }

    updateUAPeriod(period) {
        return this.http.post(this.baseUrl + 'api/UAPeriod/Update', period)
            .shareReplay();
    }
    CalculateUAPeriodResults(id: number) {
        return this.http.get(this.baseUrl + `api/UAPeriod/CalculateResults/${id}`)
            .shareReplay();
    }

    getCurrentUAPeriod() {
        return this.http.get<UAPeriodDTO>(this.baseUrl + 'api/UAPeriod/GetCurrentUAPeriod')
            .shareReplay();
    }

    getCurrentOrLastUAPeriod() {
        return this.http.get<UAPeriodDTO>(this.baseUrl + 'api/UAPeriod/GetCurrentOrLastUAPeriod')
            .shareReplay();
    }
    getLastUAPeriod() {
        return this.http.get<UAPeriodDTO>(this.baseUrl + 'api/UAPeriod/GetLastUAPeriod')
            .shareReplay();
    }

    getFuturePeriods() {
        return this.http.get<Array<UAPeriodDTO>>(this.baseUrl + 'api/UAPeriod/GetFutureUAPeriods')
            .shareReplay();
    }
}

